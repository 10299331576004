import React from "react";
import { Box, Typography,Grid,Card,CardContent } from "@mui/material";
import P4lIcon from "../UI/P4lIcon"
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';



function DeltaCard(props){

return(
  <Grid item xs={12} sm={6} md={4} lg={props.colSpan}>


  <Card>
      <CardContent >
        <Box className="flex justify-between items-center">
          <Box className="flex flex-col gap-4">
            <Typography variant='h6' >
             {props.title}
            </Typography>

            <Box className="flex flex-col gap-2">
            <Typography component='p' variant='caption' sx={{ lineHeight: '1.25rem' }}>
              {props.comparingText}
            </Typography>
    <Box className="flex gap-2 items-center">


            <Typography variant='h6'>{props.value}</Typography>
            <P4lIcon size="small" className="text-green-400" icon="p4l-arrow-up"/>
            <Typography variant='caption' sx={{ color: 'success.main' }}>
                {props.comparingValue}
              </Typography>
            </Box>

            </Box>
          </Box>

          <Box sx={{ width: '50%', height: '100px' }}>
              <SparkLineChart data={[1, 4, 2, 5, 7, 2, 4, 6]} />
            </Box>
        </Box>
      </CardContent>
    </Card>



</Grid>

)
}
export default DeltaCard;