import React,{useState,useEffect} from "react";

import Box from "@mui/material/Box";
import P4lIcon from "../UI/P4lIcon";
import Input from "../UI/Input";

import Cookies from "js-cookie";
import { fetchCompanyDetails } from "../../utils/cms";
import { FormControl } from "@mui/material";
import useTranslations from "../../hooks/use-translations";
function SocialAuth({social}){

    const [socialAuthOptions, setSocialAuthOptions] = useState([]);
    const [fieldValues, setFieldValues] = useState('');
    const language = localStorage.getItem('language') || 'pt';
    const translate = useTranslations(language);
    const [translatedText, setTranslatedText] = useState({
      transSelectLabel: ''
    });


    useEffect(() => {
      const translateLabels = async () => {
        const transSelectLabel = await translate('select-language');
        setTranslatedText({ transSelectLabel });
      };

      translateLabels();
    }, [translate]); // Re-run if the language or translate function changes
//console.log(translatedText)
    useEffect(() => {

        if (language) {
          //console.log(language)
          setFieldValues(language);

        }
        /* eslint-disable */
      }, []);


    function  handleLanguageChange  (e) {

            //console.log(e)
            localStorage.setItem('language', e.target.value);
            window.location.reload();
            setFieldValues( e.target.value);

        }
        useEffect(() => {
          const companyName = Cookies.get('brand') || 'planning4life';

          const fetchCompanyData = async () => {
            const companyDetails = await fetchCompanyDetails(companyName);
            if (companyDetails && companyDetails.data && companyDetails.data['social-auth']) {
              setSocialAuthOptions(companyDetails.data['social-auth'].filter(option => option.active === 1));
            }
          };

          fetchCompanyData();
        }, []);

//console.log(fieldValues)

  return (
    <React.Fragment>
      <Box >
        <div className="  flex flex-col justify-start gap-8 items-start ">
          <div>
            {social!=='disable' && socialAuthOptions.map((option, index) => (
              <P4lIcon
                key={index}
                href={option.url}
                disableRipple={true}
                icon={option.icon}
                className="text-white"
              />
            ))}
          </div>


        <FormControl className="w-64"  >
          <Input
            type={'select'}
            label={translatedText.transSelectLabel}
            onChange={(e) => handleLanguageChange(e)}
            name={'select-lang'}
            value={fieldValues}
            color={"primary.light"}
            className="text-white"
            icon={`p4l-${fieldValues}`}
            selectObject={{
              app_name: "core",
              model_name: "Language",
              field_key: "alias"
            }}
          />
          </FormControl>
          </div>
      </Box>

    </React.Fragment>
  );
}

export default SocialAuth;

