import React from 'react';
import { Pie , Bar ,Doughnut, Line, Radar, Scatter ,PolarArea , Chart, Bubble } from 'react-chartjs-2';
import 'chart.js/auto';
import { Box } from '@mui/material';

const Chartjs = ({  type,data, options }) => {
  // Parsing JSON strings into objects
  const chartData = JSON.parse(data);
  const chartOptions = JSON.parse(options);
  const ChartComponents = {
    Pie,
    Bar,
    Doughnut,
    Line,
    Radar,
    Scatter,
    PolarArea,
    Bubble,
    Chart
  }

  const ChartComponent = ChartComponents[type];
  return (



            <Box sx={{width: '100%' }}>
             <ChartComponent data={chartData} options={chartOptions} />
            </Box>


  );
};

export default Chartjs;
