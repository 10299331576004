import { useEffect } from "react";
import { getAccessToken } from "../utils/auth";
export const useNotificationWebSocket = (onNewNotification) => {
  const wsUrl = process.env.REACT_APP_WS_URL;

  useEffect(() => {
    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      //console.log('WebSocket connection established');
    };

    ws.onmessage = (event) => {
      //console.log('Notification received:', event.data);
      onNewNotification && onNewNotification(JSON.parse(event.data));
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = () => {
      //console.log('WebSocket connection closed');
    };

    return () => {
      ws.close();
    };
    // eslint-disable-next-line
  }, [ onNewNotification]);

  return;
};
