import React from 'react';
import { Grid } from '@mui/material';

const SectionCard = ({ children, colSpan,colspan }) => {
  //console.log(colspan)
  return (
    <Grid item xs={12}  sm={colSpan ? colSpan : colspan}>
      {children}
    </Grid>
  );
};

export default SectionCard;