import { useState, useCallback } from 'react';
import { fetchTranslationFromLabel } from '../utils/cms';

function useTranslations(language) {
  const [translations, setTranslations] = useState({});

  const translate = useCallback(async (label) => {
    // Check if the translation for the label already exists
    if (translations[label]) {
      return translations[label];
    } else {
      try {
        // Fetch new translation from the API
        const response = await fetchTranslationFromLabel(label, language);
        // Use the functional update form to ensure we have the most up-to-date state
        setTranslations((prevTranslations) => ({
          ...prevTranslations,
          [label]: response|| `\`${label}\``,
        }));
        // Return the new translation directly
        return response || `\`${label}\``;
      } catch (error) {
        console.error(`Error translating label "${label}":`, error);
        // In case of error, return the label in backticks as a fallback
        return `\`${label}\``;
      }
    }
  }, [language, translations]); // Note: Consider removing `translations` from dependencies

  return translate;
}

export default useTranslations;
