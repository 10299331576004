import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useLoaderData, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Button from './Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DrawerRight from './DrawerRight';
import MetaData from './MetaData';
import { useTheme } from '@mui/styles';
import Breadcrumb from '../ Breadcrumb/ Breadcrumb';
import P4lIcon from './P4lIcon';
import useTranslations from '../../hooks/use-translations';
const DataTable = (props) => {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const [pageSize, setPageSize] = useState(5); // State for page size
    const [totalPages, setTotalPages] = useState(1); // State for total pages
    const dataFromLoader = useLoaderData();
    const data = props.data ? props.data : dataFromLoader;
    console.log(data)
    const theme = useTheme();
    const [refreshKey, setRefreshKey] = useState(0);
    const navigate = useNavigate();
    const { label, buttons, fields, meta, breadcrumb, base_api_url } = data;
    const [searchTerm, setSearchTerm] = useState('');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [alias, setAlias] = useState('');
    const language = localStorage.getItem("language") || "pt";
    const translate = useTranslations(language);
    const [translatedText, setTranslatedText] = useState({
        transSearch: ''
      });

      useEffect(() => {
        const translateLabels = async () => {
          const transSearch = await translate('search');

          setTranslatedText({ transSearch });
        };

        translateLabels();
      }, [translate]); // Re-run if the language or translate function changes









    useEffect(() => {
        if(props.columns){
            setRows(props.rows);
            setColumns(props.columns);
        }
        else if (props.data && data.length > 0 && !props.columns) {
            const processedRows = data.map(item => ({
                id: item.identifier, // Use 'identifier' as the id for each row
                ...Object.keys(item.data).reduce((acc, key) => {
                    // Map each key to its corresponding value
                    acc[key] = item.data[key].value;
                    return acc;
                }, {})
            }));

            setRows(processedRows);

            // Generate columns dynamically from the data structure
            const dynamicColumns = Object.keys(data[0].data).map(key => ({
                field: key,
                headerName: data[0].data[key].label,
                flex: 1,
                // Special rendering for 'created' field to format the date
                renderCell: (params) => {
                    if (key === 'created') {
                        return <span>{formatDate(params.value)}</span>; // Use formatDate function for 'created'
                    }
                    // Default rendering for other fields
                    return <span>{params.value}</span>;
                }
            }));

            setColumns(dynamicColumns);
        }
    }, [props.data, props.columns, props.rows]);





    useEffect(() => {
        fetchDataFromLoader(currentPage, pageSize);
    }, [currentPage, pageSize, base_api_url, searchTerm]); // Fetch data when currentPage, pageSize, base_api_url, or searchTerm changes

    const fetchDataFromLoader = async (page, size) => {
        if(props.data) return;
        const b64fields= btoa(JSON.stringify([...fields,data.key]));
        const filter = fields.map(field => ({ [`${field}__icontains`]: searchTerm }));
        const b64filter = btoa(JSON.stringify(filter));


        const url = `${base_api_url}?page=${page}&page_size=${size}&fields=${b64fields}&filter=${b64filter}&order_by=-${data.key}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',  // Ensure cookies are included in the request
            });
            const result = await response.json();
            setTotalPages(result.pages); // Set the total pages from the response
            let filteredRows = result.results.map(item => ({
                id: item.stamp,
                ...fields.reduce((acc, field) => {
                    acc[field] = item[field];
                    return acc;
                }, {})
            }));

            // Apply search term filtering if searchTerm is not empty

            setRows(filteredRows);

            // Generate columns dynamically based on fields
            if (columns.length === 0 && result.results.length > 0) {
                const dynamicColumns = Object.keys(result.results[0])
                    .filter(key =>  key!== data.key) // Exclude 'stamp' and 'id' fields
                    .map(key => ({
                        field: key,
                        headerName: key.replace(/_/g, ' ').toUpperCase(),
                        flex: 1,
                        renderCell: (params) => {
                            if (key === 'created' || key === 'birthdate') {
                                return <span>{formatDate(params.value)}</span>;
                            }
                            return <span>{params.value}</span>;
                        }
                    }));
                setColumns(dynamicColumns);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };









    const handleRowClick = (params) => {
        navigate(`${params.id}`);
    };

    const handleSelectionModelChange = (newSelection) => {
        if (newSelection.length === 0) {
            setRefreshKey((prevKey) => prevKey + 1);
        }
        if (newSelection.length > 1) {
            const lastSelectedId = newSelection[newSelection.length - 1];
            props.onSelectionModelChange([lastSelectedId]);
        } else {
            props.onSelectionModelChange(newSelection);
        }
    };

    const isRowSelectable = (params) => {
        if (props.selectionModel)
            return props.selectionModel.length === 0 || props.selectionModel.includes(params.id);
        else
            return true;
    };

    const renderButtons = () => {
        return buttons && buttons.map((button, index) => (
            <Button
                sx={{
                    backgroundColor: theme.palette.primary[button.color] || theme.palette.primary.main,
                   borderRadius: '2.5rem', fontWeight: 700
                }}
                key={index}
                endIcon={<span className={`${button.endicon}`}></span>}
                variant={button.variant}
                size={button.size}
                buttonText={button.label}
                onClick={() => handleButtonClick(button.type, button.alias)}
            />
        ));
    };

    const handleButtonClick = (type, alias) => {
        if (type === 'new') {
            setOpenDrawer(true);
            setAlias(alias);
        }
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage( (prevValue) => prevValue-1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage( (prevValue) => prevValue+1);



        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1; // getMonth() returns a zero-based index
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;
        const formattedHours = hours < 10 ? '0' + hours : hours;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        return `${formattedDay}-${formattedMonth}-${year} ${formattedHours}:${formattedMinutes}`;
    }

    return (
        <React.Fragment>
            <Breadcrumb data={breadcrumb} />
            <Paper className='p-6 ' sx={{ backgroundColor: theme.palette.primary[100] }}>
                <Grid className='' container spacing={data ? 6 : 0}>
                    <Grid item xs={12}>
                      {!props.data && <Box className={`flex flex-col ${data && "gap-8"}`}>
                            <Typography variant='h5'>{label || ""}</Typography>


                                <Box className="ml-auto w-fit" >
                                    {renderButtons()}

                            </Box>
                            <Box className="flex justify-between items-center">
                            <TextField
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '10px', // Set the border-radius

                                }
                              }}
                                    size="small"
                                    placeholder= {translatedText.transSearch}
                                    variant="outlined"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <P4lIcon icon="p4l-search"/>
                                        ),
                                    }}
                                />
                            <Box className="flex items-center justify-end gap-2">
                                <IconButton size="large"   onClick={handlePrevPage} disabled={currentPage === 1}>
                                <span className={`p4l-previous ${currentPage === totalPages && 'text-4xl' } `}></span>
                                </IconButton>
                                <Typography variant="body1">
                                    Page {currentPage} of {totalPages}
                                </Typography>

                                <IconButton  size="large"   onClick={handleNextPage} disabled={currentPage === totalPages}>
                                <span className={`p4l-next ${currentPage === 1 && 'text-4xl' } `}></span>
                                </IconButton>

                            </Box>
                            </Box>
                        </Box> }
                    </Grid>
                    {rows.length > 0 ? (
                        <Grid className='' item xs={12}>
                            <Box sx={{ width: '100%', overflowX: 'auto' }}>
                                <Box sx={{ minWidth: '46rem' }}>
                                    <DataGrid
                                        className='bg-primary-100'
                                        autoHeight
                                        key={refreshKey}
                                        rows={rows}
                                        onRowClick={!props.columns && handleRowClick}
                                        columns={columns}
                                        disableColumnMenu
                                        slotProps={{ baseButton: { variant: 'outlined' } }}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 5,
                                                },
                                            },
                                        }}
                                        sx={{
                                            '& .MuiDataGrid-row:hover': {
                                                cursor: 'pointer',
                                            },
                                        }}
                                        checkboxSelection={props.checkbox}
                                        isRowSelectable={isRowSelectable}
                                        onRowSelectionModelChange={props.onSelectionModelChange && handleSelectionModelChange}
                                        selectionModel={props.selectionModel}
                                        hideFooterSelectedRowCount
                                        pagination
                                        disablePagination = {props.data ? false : true}
                                        paginationMode="server"
                                        pageSize={pageSize} // Add current page size state
                                        rowCount={data.count} // Add total row count for server-side pagination
                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector
                                        disableSelectionOnClick
                                        hideFooterPagination = {props.data ? false : true}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    ) : (
                        <div className="h-1/2 flex justify-center items-center">No Data to show</div>
                    )}
                    {alias && <DrawerRight open={openDrawer} alias={alias} handleClose={handleCloseDrawer} />}
                    <MetaData data={JSON.stringify(meta)} />
                </Grid>
            </Paper>
        </React.Fragment>
    );
};

export default DataTable;