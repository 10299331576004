import React from 'react';

const FinanceStatus = ({ status }) => {
    // Determine the background color based on status
    const bgColor = (() => {
        switch(status) {
            case 'GREEN':
                return 'bg-green-500'; // Assuming Tailwind CSS color
            case 'RED':
                return 'bg-red-500';
            case 'UNCHECKED':
            case 'NOT_FOUND':
                return 'bg-orange-500';
            default:
                return 'bg-gray-200'; // Default background
        }
    })();

    return (
        <div className={`flex  items-center  gap-2 font-medium text-gray-900 dark:text-white me-3 `}>
            <span className={`flex w-2.5 h-2.5  rounded-full me-1.5 flex-shrink-0 ${bgColor}`}></span>
            Finanças
        </div>
    );
};

export default FinanceStatus;
