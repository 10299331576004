import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, LinearProgress } from '@mui/material';
import useTranslations from '../../hooks/use-translations';

const ProfileProgressBar = ({ progress = 60 }) => {
  const language = localStorage.getItem("language") || "pt";
  const translate = useTranslations(language);

  const [translatedText, setTranslatedText] = useState({
    completeProfile: '',
  });

  useEffect(() => {
    const translateLabels = async () => {
      const completeProfile = await translate('complete-your-profile');
      setTranslatedText({ completeProfile });
    };

    translateLabels();
  }, [translate]);

  return (
    <Link to={"profile/my-profile"} style={{ textDecoration: 'none' }}>
      <Box className="flex flex-col items-start gap-2 w-full" sx={{ position: 'relative' }}>
        <Typography className='text-primary-main' variant="subtitle2">
          {translatedText.completeProfile}
        </Typography>
        <Box sx={{ width: '100%', position: 'relative' }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            style={{ width: '100%', height: "1.5rem" }}
          />
          <Typography
            variant="body2"
            sx={{
              position: 'absolute',
              bottom: '-1.5rem',
              right: 0,
              color: 'text.secondary'
            }}
          >
            {`${progress}%`}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

export default ProfileProgressBar;
