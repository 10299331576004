import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Button, CircularProgress, IconButton } from '@mui/material';
import { fetchChatbotResponse } from '../../utils/cms';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { motion, AnimatePresence } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css'; // Import keen-slider CSS
import PlatFormLogo from '../UI/PlatFormLogo';
import { useTheme } from '@mui/material/styles';

export default function Chat() {
  const [messages, setMessages] = useState([]);
  const [conversationId, setConversationId] = useState(undefined);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const theme = useTheme();
  const scrollbarRef = useRef(null);
  const inputRef = useRef(null); // Ref for the input field

  const sampleQuestions = [
    "How can I reset my password?",
    "What are the features of your platform?",
    "Can you help me with my account settings?",
    "How do I contact support?",
    "What are the benefits of your service?"
  ];

  const [sliderRef] = useKeenSlider({
    loop: true,
    mode: 'free',
    slides: {
      perView: 2.5,
      spacing: 15,
    },
  });

  const sendMessage = async (message,conversationId) => {
    if (!message) return;

    const newUserMessage = { text: message, sender: 'user' };
    setMessages((messages) => [...messages, newUserMessage]);

    setInputMessage('');
    setShowSuggestions(false); // Hide suggestions and logo when a message is sent

    try {
      setIsLoading(true);
      const response = await fetchChatbotResponse(message,conversationId);
      console.log(response);
      setIsLoading(false);
      const botMessage = { text: response.answer, sender: 'bot' };
      setMessages((messages) => [...messages, botMessage]);
      setConversationId(response.conversation_id)
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching chatbot response:', error);
      setMessages((messages) => [...messages, { text: 'Error fetching response', sender: 'bot' }]);
    }
  };

  const scrollToBottom = () => {
    const scrollbar = scrollbarRef.current;
    if (scrollbar) {
      setTimeout(() => {
        scrollbar._container.scrollTop = scrollbar._container.scrollHeight;
      }, 100);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleExpand = () => {
    setIsExpanded(true);
    document.body.style.overflow = 'hidden'; // Disable outer scroll

    // Ensure that scrolling finishes before focusing
    requestAnimationFrame(() => {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus(); // Focus the input field
        }
      }, 300); // Slight delay ensures iOS has handled layout
    });
  };

  const handleClose = () => {
    setIsExpanded(false);
    setMessages([]); // Clear the messages when closing the expanded chat
    setConversationId(undefined);
    setInputMessage(''); // Clear the input field
    setShowSuggestions(true); // Show suggestions and logo when chat is closed
    document.body.style.overflow = 'auto'; // Re-enable outer scroll
  };

  const handleQuestionClick = (question) => {
    sendMessage(question,conversationId); // Automatically send the question when the card is clicked
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ scale: 1 }}
        animate={{ scale: isExpanded ? 1 : 1, height: isExpanded ? '100vh' : 'auto' }}
        exit={{ scale: 1 }}
        style={{
          width: '100%',
          backgroundColor: theme.palette.background.paper,
          color: isExpanded ? theme.palette.text.primary : theme.palette.common.white,
          padding: theme.spacing(4),
          borderRadius: 6,
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          position: isExpanded ? 'fixed' : 'relative',
          top: 0,
          left: 0,
          right: 0,
          zIndex: isExpanded ? 9999 : 10,
          overflow: isExpanded ? 'hidden' : 'visible', // Disable overflow when expanded
          WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
            Pergunta à Sónia
          </Typography>
          {isExpanded && (
            <IconButton onClick={handleClose} sx={{ color: theme.palette.text.primary }} disabled={isLoading}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>

        {isExpanded && showSuggestions && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: 1,
              height: '100%',
              transform: 'translateY(25%)', // Apply translateY to center the content vertically
            }}
          >
            <PlatFormLogo className="max-h-8" type="entrance" />
            <Typography variant="body2" sx={{ mt: 2, color: theme.palette.text.secondary }}>
              Powered by ChatGPT
            </Typography>

            <Box ref={sliderRef} className="keen-slider" sx={{ width: '100%', mt: 8 }}>
              {sampleQuestions.map((question, index) => (
                <Box
                  key={index}
                  className="keen-slider__slide"
                  onClick={() => handleQuestionClick(question)}
                  sx={{
                    width: '16rem', // Fixed width for all cards
                    height: '9rem', // Fixed height for all cards
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: theme.shape.borderRadius,
                    textAlign: 'center',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <Typography variant="caption" sx={{ mb: 2, fontWeight: 'bold' }}>
                    {question}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        <PerfectScrollbar
          ref={scrollbarRef}
          style={{
            width: '100%',
            flexGrow: 1,
            maxHeight: isExpanded ? 'calc(100vh - 150px)' : '15rem',
            overflow: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 4 }}>
            {messages.map((msg, index) => (
              <Typography
                key={index}
                sx={{
                  boxShadow: 1,
                  borderRadius: 2,
                  maxWidth: '100%',
                  width: 'fit-content',
                  wordWrap: 'break-word',
                  padding: theme.spacing(1, 2),
                  marginLeft: msg.sender === 'user' ? 'auto' : undefined,
                  backgroundColor: msg.sender === 'user' ? (isExpanded ? theme.palette.primary.light : 'rgba(255, 255, 255, 0.2)') : (isExpanded ? theme.palette.background.default : 'rgba(255, 255, 255, 0.1)'),
                  color: isExpanded ? theme.palette.text.primary : theme.palette.common.white,
                  textAlign: msg.sender === 'user' ? 'right' : 'left',
                }}
              >
                {msg.text}
              </Typography>
            ))}
            {isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                <CircularProgress color="inherit" />
              </Box>
            )}
          </Box>
        </PerfectScrollbar>

        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            sendMessage(inputMessage,conversationId);
          }}
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mt: 'auto', // Ensures the form stays at the bottom
          }}
        >
          {isExpanded ? (
            <>
              <TextField
                fullWidth
                size="small"
                placeholder="Type a message..."
                value={inputMessage}
                inputRef={inputRef} // Assign the ref to the input field
                onClick={handleExpand} // Expand when the input is clicked
                onChange={(e) => setInputMessage(e.target.value)}
                disabled={isLoading}
                autoComplete="off"
                sx={{
                  backgroundColor: theme.palette.background.default,
                  input: { color: theme.palette.text.primary },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme.palette.text.primary,
                    },
                    '&:hover fieldset': {
                      borderColor: theme.palette.text.primary,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme.palette.text.primary,
                    },
                  },
                }}
              />
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                Send
              </Button>
            </>
          ) : (
            <Box
              onClick={handleExpand}
              sx={{
                width: '100%',
                padding: '0.5rem 1rem',
                backgroundColor: theme.palette.background.default,

                border: `1px solid ${theme.palette.text.primary}`,
                color: theme.palette.text.primary,
                cursor: 'text',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                minHeight: '2.5rem',
              }}
            >
              <Typography variant="body1">Type a message...</Typography>
            </Box>
          )}
        </Box>
      </motion.div>
    </AnimatePresence>
  );
}
