import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCSRFToken } from '../utils/auth';

function VerifyEmail() {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/', { replace: true });
      return;
    }

    const confirmEmail = async () => {
      try {
        const csrfToken = getCSRFToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}_allauth/browser/v1/auth/email/verify`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          body: JSON.stringify({ key: token }),
          credentials: 'include'
        });

        if (response.ok) {
          navigate('/notification', {
            state: {
              title: 'email-confirmed',
              message: 'email-confirmed-success'
            }
          });
        } else {
          navigate('/notification', {
            state: {
              title: 'email-confirmed-invalid-link',
              message: 'email-confirm-link-expired-or-invalid'
            }
          });
        }
      } catch (error) {
        console.error('Failed to confirm email:', error);
        navigate('/notification', {
          state: {
            title: 'email-confirmed-invalid-link',
            message: 'email-confirm-link-expired-or-invalid'
          }
        });
      }
    };

    confirmEmail();
  }, [token, navigate]);

  return null;
}

export default VerifyEmail;
