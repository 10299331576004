import React from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLoaderData } from 'react-router-dom';

function Profile(props) {
    const loaderData = useLoaderData();
    const { name, job, civil_state } = loaderData;

    return (
        <Box>
            {/* Profile Header */}
            <Box style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className=" p-8 text-black flex flex-col items-center">
                {/* Profile Picture and Rating */}
                <Box className="relative flex flex-col items-center">
                    <Avatar
                        src="profile_image_url_here" // Replace with actual image URL or use props
                        alt="Profile Picture"
                        sx={{ width: 100, height: 100 }}
                    />
                    <Box className="absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4 bg-pink-500 text-white py-1 px-2 rounded-full">
                        <Typography variant="caption">4.5</Typography>
                    </Box>
                </Box>

                {/* Name and Info */}
                <Typography variant="h6" className="mt-4 font-bold text-black">
                    {name}
                </Typography>
                <Typography variant="body2" className="mt-2 text-gray-600">
                    {civil_state} {job}
                </Typography>

                {/* Stats */}
                <Box className="flex justify-around w-full mt-8">
                    <Box className="flex flex-col items-center">
                        <Typography variant="h6" className="font-bold text-black">
                            5
                        </Typography>
                        <Typography variant="body2" className="text-sm text-gray-600">
                            Seguros
                        </Typography>
                    </Box>
                    <Box className="flex flex-col items-center">
                        <Typography variant="h6" className="font-bold text-black">
                            5
                        </Typography>
                        <Typography variant="body2" className="text-sm text-gray-600">
                            Créditos
                        </Typography>
                    </Box>
                    <Box className="flex flex-col items-center">
                        <Typography variant="h6" className="font-bold text-black">
                            1
                        </Typography>
                        <Typography variant="body2" className="text-sm text-gray-600">
                            Rendimentos
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* Cards Grid */}
            <Box id="cards-grid" className="grid grid-cols-2 gap-4 justify-center p-16 w-full sm:w-2/3 xl:w-1/2 mx-auto">
                <Card link="/profile/my-profile" iconClass="p4l p4l-profile text-blue-800" title="Editar Perfil" />
                <Card link="/account/my-account" iconClass="p4l p4l-golden-bars text-green-500" title="A Minha Conta" />

                <Card iconClass="p4l p4l-family-dependents text-pink-400" title="Família" />
                <Card iconClass="p4l p4l-email text-black" title="Convidar" />

                <Card link="/pages/patrimony" iconClass="p4l p4l-calculator2 text-blue-500" title="Patrimônio" />
                <Card link="/pages/financial-health" iconClass="p4l p4l-health text-green-500" title="Saúde Financeira" />
                <Card iconClass="p4l p4l-support text-orange-500" title="Ajuda" />

                <Card link="/pages/account_my-documents" iconClass="p4l p4l-documents text-green-500" title="Os meus Documentos" />
            </Box>
        </Box>
    );
}

function Card({ link, iconClass, title }) {
    return (
        <Box
            style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }}
            className="flex flex-col gap-1 w-full items-center justify-center border rounded-lg px-6 py-4 text-black bg-white"
        >
            <Link to={link || '#'} className="flex flex-col gap-2 w-full items-center justify-center">
                <span className={`text-5xl ${iconClass}`}></span>
                <span className="font-bold text-center text-xl text-black">{title}</span>
            </Link>
        </Box>
    );
}

export default Profile;
