import React,{useState,useEffect,useContext} from "react";

import { Divider,Typography } from "@mui/material";

import { Link } from "react-router-dom";
import AuthForm from "../components/Auth/AuthForm";

import { json ,redirect} from 'react-router-dom';
import FormLayout from "../components/UI/DynamicForm/FormLayout";

import useTranslations from "../hooks/use-translations";
import LinkMui from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../store/context/auth-context__";
function Register() {


  const authCtx = useContext(AuthContext);
  const language = localStorage.getItem("language") || "pt";
  const navigate =useNavigate();
  const translate = useTranslations(language);

  const [translatedTexts, setTranslatedTexts] = useState({
    alreadyHaveAccount: '',
    loginHere: ''
  });
  useEffect(() => {
    const loadTranslations = async () => {
      const alreadyHaveAccount = await translate('register-already-have-account'); // Use your actual translation key
      const loginHere = await translate('register-login-here'); // Use your actual translation key
      setTranslatedTexts({ alreadyHaveAccount, loginHere });
    };

    loadTranslations();
  }, [ translate]);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      const response = await fetch(process.env.REACT_APP_API_URL +'appauth/get-csrf-token/', {
        method: 'GET',
        credentials: 'include',  // Ensure cookies are included in the request
      });
      const result = await response.json();
      console.log(result);
     localStorage.setItem('csrfToken',result.csrf_token);
    };

    fetchCsrfToken();
  }, []);

  useEffect(() => {
    authCtx.refetch();
    if(authCtx.isLoggedIn){
      navigate("/");
    }

    // eslint-disable-next-line
  }, [authCtx.isLoggedIn, navigate]);



function submitHandler(){


navigate("/login");


  }



  return (
<>
   <AuthForm>
        <FormLayout submitHandler={submitHandler} formType='router' colSpan={12} className="flex flex-col  items-center gap-8"  />
        <Typography className="flex text-white gap-2 " variant="body2">
        {translatedTexts.alreadyHaveAccount}
        <Link to="/login"> <LinkMui  sx={{ color: 'primary.accent', textDecoration: 'underline' }}>{translatedTexts.loginHere}</LinkMui></Link>
      </Typography>
<Divider className='text-white w-full'>ou</Divider>
        </AuthForm>
    </>




  );
}

export default Register;





export async function action({ request }) {

  const { REACT_APP_API_URL } = process.env;


  const data = await request.formData();
  //console.log(data)

  const authData = {
    email: data.get('email'),
    password: data.get('password'),
    "confirm_password": data.get('confirm_password'),
    username: data.get('username'),

  };

  //console.log(authData)

  if(authData.password !== authData["confirm_password"]){

   return { ok:false, "confirm_password": ['Passwords dont match'] };
  }

  const response = await fetch(REACT_APP_API_URL+'_allauth/browser/v1/auth/signup' , {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem("csrfToken"),
    },
    body: JSON.stringify(authData),
    credentials: 'include',

  });

  if (response.status === 400) {
    const errorData = await response.json();
    //console.log(errorData)
    return { ok: false, status: response.status, data: errorData };
  }

  if (!response.ok) {
    throw json({ message: 'Could not authenticate user.' }, { status: 500 });
  }

await response.json();

  //console.log(resData)
  return redirect("/");


}
