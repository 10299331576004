import React from 'react';
import ButtonMUI from '@mui/material/Button';

import LoadingButton from '@mui/lab/LoadingButton';

const Button = ({ buttonText, isLoading, ...props }) => {





    if (isLoading) {
      return (
        <LoadingButton {...props} loading>
          {buttonText}
        </LoadingButton>
      );
    } else {

      return (
        <ButtonMUI {...props}>
          {buttonText}
        </ButtonMUI>
      );
    }
  };

  export default Button;
