
import React, { createContext, useContext, useState } from 'react';

// Create context
const ActionDataContext = createContext();

// Create a provider
export const ActionDataProvider = ({ children }) => {
  const [actionData, setActionData] = useState(null);

  // The value that will be accessible to all children
  const value = {
    actionData,
    setActionData,

  };






  return <ActionDataContext.Provider value={value}>{children}</ActionDataContext.Provider>;
};

// Custom hook for easy access to context
export const useActionDataContext = () => {
  const context = useContext(ActionDataContext);
  if (context === undefined) {
    throw new Error('useActionData must be used within an ActionDataProvider');
  }
  return context;
};
