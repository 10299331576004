import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Grid } from '@mui/material';

import DeltaCard from '../components/Widgets/DeltaCard';

import StateCard from '../components/Widgets/StateCard';
import GroupCard from '../components/UI/Dashboard/GroupCard';
import SectionCard from '../components/UI/Dashboard/SectionCard';

import DonutChart from '../components/Widgets/DonutChart';
const Dashboard = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);


  return (

    <Grid container spacing={2}>

    <SectionCard colSpan={6}>
      <GroupCard title="Geral">

    <StateCard colSpan={4} />

    <StateCard colSpan={4} />
    <StateCard colSpan={4} />
    </GroupCard>
      </SectionCard>
      <SectionCard colSpan={8}>
      <GroupCard title="Leads" backgroundColor={'transparent'}>
    <DeltaCard title="Total" value={897} comparingText="7 dias atrás" comparingValue="8%" colSpan={4}/>
    <DeltaCard title="Seguros" value={181} comparingText="7 dias atrás" comparingValue="11%" colSpan={4}/>
    <DeltaCard title="Crédito" value={674} comparingText="7 dias atrás" comparingValue="34%" colSpan={4}/>
    </GroupCard>
      </SectionCard>

      <SectionCard colSpan={6}>

    <DonutChart title="Origem das Leads" />

      </SectionCard>







    {/* Other Grid Items */}

  </Grid>
  );

};

export default Dashboard;