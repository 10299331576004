import React,{useEffect,useState,useCallback,useContext} from 'react';
import { useRouteError } from 'react-router-dom';
import { Box,Typography } from '@mui/material';
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import useTranslations from '../hooks/use-translations';
import { useNavigate } from 'react-router-dom';
import { useSnackBar } from '../store/context/snackbar-context';
import AuthContext from '../store/context/auth-context__';
function ErrorPage() {
  //default error messages
  let error = useRouteError();
  const language = localStorage.getItem("language") || "pt";
  const translate = useTranslations(language);
  const navigate = useNavigate();
  const { notify } = useSnackBar();
  const authCtx = useContext(AuthContext);
  // Initialize state for translated text
  const [translatedText, setTranslatedText] = useState({
    backToHome: ''
  });

  //console.log(error)
// ** Styled Components
const BoxWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90vw'
  }
}))
useEffect(() => {
  const translateLabels = async () => {
    const transBackToHome = await translate('back-to-home');

    setTranslatedText({ transBackToHome });
  };

  translateLabels();
}, [translate]); // Re-run if the language or translate function changes


const handleError = useCallback(async () => {
  if (!error) return;

  switch (error?.status) {
      case 401:
          notify('Object not found','error');
          authCtx.refetch();
          navigate('');
          break;
      case 404:

          notify('Page not found', 'error');
          navigate('..'); // might redirect to a custom 404 page
          break;
      case 500:
          notify('Something went wrong',  'error' );
          navigate(''); // might redirect to the home page or an error page
          break;
      default:
          notify('An unexpected error occurred.', 'error');
          navigate(''); // default error handling
          break;
  }
  // eslint-disable-next-line
  error=undefined;
  // eslint-disable-next-line
}, [error]);


useEffect(() => {
  handleError();
  // eslint-disable-next-line
}, []);

const Img = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  maxHeight:250,
  [theme.breakpoints.down('lg')]: {
    height: 450,
    marginTop: theme.spacing(10)
  },
  [theme.breakpoints.down('md')]: {
    height: 400
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(13)
  }
}))
console.log(error)
console.log(error.data)
if (!error || (error.status !== 401 && error.status !== 404  && error.status !== 500 )) {
  return  <Box className='content-center'>
  <Box className="p-6 flex flex-col  items-center justify-center text-center" sx={{  }}>
    <BoxWrapper>
      <Typography variant='h1'>{error.status}</Typography>
      <Typography variant='h5' sx={{ mb: 1, fontSize: '1.5rem !important' }}>
      {error.data?.message} 🔐
      </Typography>

    </BoxWrapper>
    <Img  alt='error-illustration' src={`${process.env.PUBLIC_URL}/static/images/cover-guide-web-design-1.svg`} />
    <Button href='/' variant='contained' sx={{ px: 5.5 }}>
          {translatedText.transBackToHome}
        </Button>
  </Box>

</Box>

}

return null
}

export default ErrorPage;