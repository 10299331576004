import { Typography } from "@mui/material"
import FormLayout from "../components/UI/DynamicForm/FormLayout";
import LinkMui from '@mui/material/Link';
import { Link } from "react-router-dom";
import AuthForm from "../components/Auth/AuthForm";
import { json } from "react-router-dom";
import useTranslations from "../hooks/use-translations";
import React, { useState, useEffect } from "react";
import { useActionData } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function ForgotPassword(){
    const actionData = useActionData();
    //console.log(actionData)
    const language = localStorage.getItem("language") || "pt";
    const translate = useTranslations(language);
    const navigate = useNavigate();
    const [translations, setTranslations] = useState({
      emailInstructions: '',
      backLogin: '',
      forgotPassword:''
    });

    if (actionData && actionData.ok) {
      navigate("/notification", {
          state: {
              title:"notification-forgot-password-verify-email",
              message: "notification-forgot-password-instructions",
              email:actionData.email
              // Assuming you're sending back the email as part of the actionData
          }
      });
  }


    useEffect(() => {
        const loadTranslations = async () => {
          const emailInstructions = await translate('forgot-password-instructions'); // Use your actual translation key
          const backLogin = await translate('forgot-password-back-login'); // Use your actual translation key
          const forgotPassword = await translate('forgot-password'); // Use your actual translation key
          setTranslations({ emailInstructions, backLogin,forgotPassword });
        };

        loadTranslations();
      }, [translate]);

    return(
<>
<AuthForm  social='disable'>

<Typography  className="text-white" variant='h5'> {translations.forgotPassword}</Typography>
<Typography className="text-white" variant='body1'>
{translations.emailInstructions}
              </Typography>
              <FormLayout colSpan={12} formType="router" className="flex flex-col items-center gap-8"  />
              <Typography className="flex gap-2 text-white" variant="body2">

        <Link to="/login"><LinkMui sx={{ color: 'primary.accent', textDecoration: 'underline' }}  >{translations.backLogin}</LinkMui></Link>
      </Typography>

      </AuthForm>
</>
    );
}

export default ForgotPassword;


export async function action({ request }) {


    const { REACT_APP_API_URL } = process.env;


    const data = await request.formData();


    const authData = {
      email: data.get('email'),

    };





    const response = await fetch(REACT_APP_API_URL+'_allauth/browser/v1/auth/password/request' , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': localStorage.getItem("csrfToken"),
      },
      credentials: 'include',
      body: JSON.stringify(authData),
    });

    if (response.status === 400) {
      const errorData = await response.json();
      //console.log(errorData)
      return { ok: false, status: response.status, data: errorData };
    }

    if (!response.ok) {
      throw json({ message: 'Could not send password reset' }, { status: 500 });
    }

    await response.json();
    //console.log(resData)
    //return redirect('/login');
    return {ok:true , response:response ,email:authData.email};


  }
