import React from 'react';
import { useLoaderData } from 'react-router-dom';
import MetaData from '../components/UI/MetaData';
import Chat from '../components/FloatingChat/Chat';
import ContentPage from './ContentPage';
import Swiper from '../components/Swiper/Swiper';
import Button from '../components/UI/Button';
import { Paper,Box,Tooltip,Chip } from '@mui/material';
import {Typography} from '@mui/material';
import { useTheme } from '@mui/material/styles';
function Home() {
  const loaderData = useLoaderData();
  //console.log(loaderData);

  const meta = loaderData?.meta || "";

  const theme = useTheme();

  return (
    <React.Fragment>
  <MetaData data={JSON.stringify(meta)}/>
  <div className='flex flex-col gap-16'>
  <Box display="flex" justifyContent="flex-start" gap={1}>

  <Chip   sx={{
        fontSize: '1rem', // Increase font size (adjust as needed)
        padding: '0.5rem',    // Optionally adjust padding for better appearance
      }} clickable label="Seguros" size="medium" variant="outlined" />


<Chip   sx={{
        fontSize: '1rem', // Increase font size (adjust as needed)
        padding: '0.5rem',    // Optionally adjust padding for better appearance
      }} clickable label="Créditos" size="medium" variant="outlined" />


    </Box>
  <Chat/>
  <Swiper/>


<Paper elevation={0} className='p-6'>
<Typography className='flex items-center justify-center gap-4' variant="h5"><span className='p4l-trophy3 text-4xl text-yellow-400 border p-2 rounded-lg '></span> Resumo</Typography></Paper>
    <ContentPage/>
    </div>
    </React.Fragment>
  );
}

export default Home;



