import React, { useState,useEffect,useRef } from 'react';
import { IconButton, Box, TextField, Typography, useTheme  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fetchChatbotResponse } from '../../utils/cms';

import Draggable from 'react-draggable';
import Button from '../UI/Button';
import PerfectScrollbar from 'react-perfect-scrollbar'



export default function ChatWindow({ closeChat }) {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const scrollbarRef = useRef(null);





  const sendMessage = async (e) => {
    e.preventDefault(); // Prevents the default form submission
    const trimmedMessage = inputMessage;
    if (!trimmedMessage) return;

    const newUserMessage = { text: trimmedMessage, sender: 'user' };
    setMessages(messages => [...messages, newUserMessage]);

    setInputMessage(''); // Clear the input field immediately after sending

    try {
      setIsLoading(true);
      const response = await fetchChatbotResponse(trimmedMessage);
      setIsLoading(false);
      const botMessage = { text: response.answer, sender: 'bot' }; // Adjust based on your API response structure
      setMessages(messages => [...messages, botMessage]);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching chatbot response:', error);
      // Optionally handle error by displaying a message in the chat window
      setMessages(messages => [...messages, { text: 'Error fetching response', sender: 'bot' }]);
    }
  };





  const scrollToBottom = () => {
    const scrollbar = scrollbarRef.current;
    // This ensures that the scroll action happens after the DOM updates
    if (scrollbar) {
      setTimeout(() => {
        scrollbar._container.scrollTop = scrollbar._container.scrollHeight;
      }, 100);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (

    <Draggable>
    <Box style={{ cursor: 'move' }} sx={{
      position: 'fixed',
      bottom: 80,
      right: 150,
      width: 600,
      height: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 5,
    }}>

      <Box sx={{ p:2, display: 'flex', alignItems:"start", justifyContent: 'space-between' }}>
      <Typography variant='h6' >Chat</Typography>
        <IconButton onClick={closeChat}>
          <CloseIcon />
        </IconButton>
      </Box>
      <PerfectScrollbar ref={scrollbarRef}>
      <Box sx={{ p:2, mt:1, flexGrow: 1, overflow: 'auto', flexDirection: 'column-reverse' }}>

        {messages.map((msg, index) => (
          <Typography variant='subtitle1' key={index} sx={{

            boxShadow: 1,
            borderRadius: 2,
            maxWidth: '100%',
            width: 'fit-content',
            marginBottom:3,
            wordWrap: 'break-word',
            p: theme => theme.spacing(1, 3),
            ml:  msg.sender === 'user' ? 'auto' : undefined,
            borderTopLeftRadius:  msg.sender !== 'user' ? 0 : undefined,
            borderTopRightRadius: msg.sender === 'user' ? 0 : undefined,
            textAlign: msg.sender === 'user' ? 'right' : 'left',
            backgroundColor: msg.sender==='user' ? 'primary.main' :  'background.paper',
            color: msg.sender==='user' ? 'common.white' : 'text.primary',

          }}>
            {msg.text}
          </Typography>
        ))}


      </Box>

</PerfectScrollbar >
      <Box component="form" onSubmit={sendMessage} sx={{   boxShadow: theme.shadows[1],
    padding: theme.spacing(1, 2),

    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,display: 'flex', alignItems: 'center', pt: 2 }}>
        <TextField
          fullWidth
          size="small"
          placeholder="Type a message..."
          value={inputMessage}
          sx={{ '& .MuiOutlinedInput-input': { pl: 0 }, '& fieldset': { border: '0 !important' } }}
          onChange={(e) => setInputMessage(e.target.value)}
        />
        <Button isLoading={isLoading} type="submit" variant="contained" buttonText={'Send'} >Send</Button>
      </Box>

    </Box>
    </Draggable>

  );
}
