import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Link, Typography, Paper } from '@mui/material';

const Breadcrumb = ({ data }) => {  // Changed 'breadcrumbs' to 'data'
  const maxChars = 20; // You can change this value as needed

  // Function to truncate and add ellipsis if necessary
  const truncate = (str, maxLength) => {
    if (str.length > maxLength) {
      return `${str.slice(0, maxLength)}...`;
    }
    return str;
  };

  if (!data || data.length === 0) {
    return null; // Don't display breadcrumbs if none are provided
  }

  return (
    <Paper className="p-8">
      <Breadcrumbs className="text-primary-main" aria-label="breadcrumb">
        {data.map((crumb, index) => {  // Changed 'breadcrumbs' to 'data'
          const last = index === data.length - 1; // Check if this is the last item

          // For the last item, display as text. Otherwise, as a clickable link
          if (last) {
            return <Typography className="capitalize text-primary-main" key={index}>{truncate(crumb.label, maxChars)}</Typography>;
          } else {
            return (
              <Link
                className="capitalize text-primary-main"
                component={RouterLink}
                to={crumb.url || "/"} // Ensure to handle empty URLs
                key={index}
              >
                {truncate(crumb.label, maxChars)}
              </Link>
            );
          }
        })}
      </Breadcrumbs>
    </Paper>
  );
};

export default Breadcrumb;
