import React, { createContext, useContext, useState } from 'react';
import useTranslations from '../../hooks/use-translations';

const TranslationContext = createContext({
  translations: {},
  requestTranslation: (label) => label, // Placeholder function
});

export const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const translate = useTranslations(localStorage.getItem('language') || 'pt');

  const requestTranslation = async (label) => {
    if (translations[label]) {
      return translations[label]; // Return the translation if it's already loaded
    }
    // Return a fallback while loading the translation
    const fallback = label;
    if (!translations[label]) {
      try {
        const translation = await translate(label);

        setTranslations((prev) => ({ ...prev, [label]: translation }));
        return translation;
      } catch (error) {
        console.error(`Translation error for ${label}:`, error);
      }
    }
    return fallback;
  };

  return (
    <TranslationContext.Provider value={{ translations, requestTranslation }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationsContext = () => useContext(TranslationContext);
