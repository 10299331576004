import React, { useState, useEffect } from 'react';
import { json } from 'react-router-dom';
import { CardHeader,CardContent,Box, Button, IconButton,  TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CardActions from '@mui/material/CardActions';
import DataTable from './Datatable';
const SelectDatatable = ({ onClose, onConfirm, data }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  //console.log(data)
  // Replace 'YOUR_API_ENDPOINT' with your actual endpoint URL
  const fetchData = async (searchTerm) => {
    const url = `webapp/updater`;
    const token = localStorage.getItem('token');
    const headers = token ? { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json' };
    try {
      // Replace 'YOUR_API_ENDPOINT' with your actual endpoint URL
      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
        method: 'POST',
        headers:headers,
        body: JSON.stringify({
          action: "select_search",
          search_term: searchTerm,
          fields:data[0].search.filter_search,
          app_name:data[0].search.app_name,
          model_name:data[0].search.model_name,
          model_key_id:data[0].search.model_key_id,
          field_key:data[0].field_key
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      const filteredData = result.data.filter(item => item !== null);
      // Assuming the API returns an array of items
      setRows(filteredData);
      //console.log(filteredData)
    } catch (error) {
      setRows([]); // Optionally handle errors by clearing the table or showing an error message
      throw json({ message: error.text }, { status: error.status });


    }
  };
//console.log(rows);
  const renderButtons = () => {
    return data[0].search.buttons.map((button, index) => {
      if (button.type === 'submit') {
        return (
          <Button
            key={index}
            variant={button.variant}
            color={button.color}
            size={button.size}
            onClick={handleOk}
          >
            {button.label}
          </Button>
        );
      } else if (button.type === 'cancel') {
        return (
          <Button
            key={index}
            variant={button.variant}
            color={button.color}
            size={button.size}
            onClick={onClose}
          >
            {button.label}
          </Button>
        );
      }
      return null; // Handle other button types as needed
    });
  };

//console.log(rows)

  /*useEffect(() => {
    setColumns(data[0].search.filter_search);
  }, [data]);*/

  useEffect(() => {
    // Assuming 'data[0].fields' contains the necessary information to construct the columns
    const dynamicColumns = Object.entries(data[0].fields).map(([key, value]) => ({
      field: key,
      headerName: value.label,
      flex: 1, // Assuming you want to give each column equal flexibility
    }));

    setColumns(dynamicColumns);
  }, [data]);




  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length >= 3) {
        fetchData(searchTerm);
      } else {
        setRows([]); // Reset/clear the table if the search term is too short

      }
    }, 500); // Delay for debouncing

    return () => clearTimeout(delayDebounceFn); // Cleanup
      // eslint-disable-next-line
  }, [searchTerm]);

  const handleOk = () => {
    const selectedItem = rows.find(item => item.id === selectedId);
    //console.log(selectedItem)
    if (selectedItem) {
      onConfirm(selectedItem);
    }
    onClose();
  };
//console.log(rows)
const handleSelectionChange = (selectedIds) => {
  // Assuming single selection for simplicity
  const lastSelectedId = selectedIds.length > 0 ? selectedIds[0] : '';
  //console.log(lastSelectedId)
  //console.log(selectedIds)
  setSelectedId(lastSelectedId);
};
return (
  <Box>
    <CardHeader title={data && data[0].search.label} />
    <CardContent>
    <TextField
      fullWidth
      variant="outlined"
      size='small'
      placeholder={data[0].search.placeholder}
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        endAdornment: (
          <IconButton onClick={() => setSearchTerm('')}>
            <SearchIcon />
          </IconButton>
        ),
      }}
    />
</CardContent>

<CardContent>

    <DataTable
        columns={columns}
        rows={rows}
        checkbox={true}
        data={data}

        onSelectionModelChange={handleSelectionChange}
        selectionModel={selectedId ? [selectedId] : []}
      />
      </CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
      <CardActions>
        {data[0].search.buttons && data[0].search.buttons.length > 0 && renderButtons()}
      </CardActions>
    </Box>

  </Box>
);
};


export default SelectDatatable;
