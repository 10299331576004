import React from 'react';
import { Box, Typography, Divider,IconButton } from '@mui/material'; // Corrected import here
import { Link } from 'react-router-dom';

const Footer = React.forwardRef(({ footerItems }, ref) => {

  //console.log(footerItems);
  return (
<footer ref={ref} className='bg-primary-100 w-full text-primary-main'>
      <Box

          className=" w-full text-center shadow-lg p-6"
          >
        <Typography className='flex flex-wrap gap-4 justify-end' variant='subtitle2'>
          {footerItems.items && footerItems.items.map((item, index) => (
            <React.Fragment key={index}>
              <Link
                to={"/"+item.destination || "#"} // Replace "#" with appropriate logic if needed
                className='cursor-pointer flex gap-2 items-center'
                underline="hover"
              >
                 {item.icon && <IconButton size='small'>
                <span className={`${item.icon ? item.icon : ''} w-fit  h-full`}></span>
              </IconButton> }
                {item.name}
              </Link>
              {item.divider && <Divider orientation="vertical" flexItem />}
            </React.Fragment>
          ))}
        </Typography>
      </Box>
      </footer>

  );
});

export default Footer;
