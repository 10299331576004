import { createTheme } from "@mui/material";
import { ptBR } from '@mui/x-date-pickers/locales';

const theme = createTheme({
  palette: {
    primary: {
      // Revised color definitions
      dark: '#1D1D1D',  // Darkest shade, for focused text or high emphasis elements
      main: '#323e4d',  // Main color for primary elements, also used as a neutral mid-tone
      light: '#f7f7fa', // Lightest shade, for backgrounds and low emphasis areas
      white: '#FEFFFF', // High contrast against dark colors for text
      black: '#1D1D1D', // Strong emphasis for text or elements (similar to dark)
      accent:'#e62686',
      // Adjusted color weights
      100: '#ffffff',   // White, for maximum contrast backgrounds or lightest areas
      200: '#f7f7fa',   // Very light grey, slightly darker than white for subtle differentiation
      300: '#f7f7fa',   // Set as requested, lightest practical background color
      400: '#323e4d',   // Set as requested, neutral for primary elements
      500: '#2c3742',   // Slightly darker than main, for less emphasized text or secondary elements
      600: '#242f38',   // Darker, for secondary navigation or lower hierarchy text
      700: '#1b252f',   // Even darker, for active states or important UI controls
      800: '#131a22',   // Very dark grey, approaching black, for maximum emphasis and contrast
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
}, ptBR);

export default theme;
