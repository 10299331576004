import { useState, forwardRef } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const ImgStyled = styled('img')(({ theme }) => ({
    height: 120,
    borderRadius: 4,
    marginRight: theme.spacing(5)
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        textAlign: 'center'
    }
}));

const UploadImage = forwardRef(({ name, label }, ref) => {
    const [imgSrc, setImgSrc] = useState('');

    const handleInputImageChange = event => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImgSrc(e.target.result); // This will trigger a re-render
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {imgSrc && <ImgStyled src={imgSrc} alt='Uploaded Pic' />}
            <ButtonStyled component='label' variant='outlined'>
                {label}
                <input
                    hidden
                    type='file'
                    onChange={handleInputImageChange}
                    id='account-settings-upload-image'
                    ref={ref}
                    name={name}
                />
            </ButtonStyled>
        </Box>
    );
});

export default UploadImage;
