import React from 'react';
import DrawerRight from '../components/UI/DrawerRight';
import DataTable from '../components/UI/Datatable';
import FormLayout from '../components/UI/DynamicForm/FormLayout';

const DynamicComponent = (props) => {
  let dynamicContent;

  if (props.type === 'datatable') {
    dynamicContent = <DataTable />;
  } else if (props.type === 'form') {
    dynamicContent = <FormLayout context={"update"} />;
  } else if (props.type === 'form-panel') {
    dynamicContent = <DrawerRight />;
  }

  return (
    <React.Fragment>
      {dynamicContent}
    </React.Fragment>
  );
};

export default DynamicComponent;
