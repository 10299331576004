
import { Helmet } from "react-helmet";
function MetaData(props){


    return (

    <Helmet>
    <meta name="mobile-menu" content={props.data} />
    </Helmet>
    )

}

export default MetaData;