import React, { createContext, useContext, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import P4lIcon from '../../components/UI/P4lIcon';
// Create the context
const SnackBarContext = createContext();

// Provider component, renaming it for expected imports
export const SnackBarContextProvider = ({ children }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const notify = useCallback((message, type = 'default') => {
        enqueueSnackbar(message, {
            variant: type, // can be default, error, success, warning, info
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
            action: (key) => (

                  <P4lIcon className="text-white" onClick={() => closeSnackbar(key)} icon={ "p4l-cross"} />
            ),
        });
    }, [enqueueSnackbar, closeSnackbar]);

    // The value that will be given to the context
    const contextValue = {
        notify
    };

    return (
        <SnackBarContext.Provider value={contextValue}>
            {children}
        </SnackBarContext.Provider>
    );
};

// Custom hook to use the notification context, keeping the naming consistent
export const useSnackBar = () => {
    const context = useContext(SnackBarContext);
    if (!context) {
        throw new Error('useSnackBar must be used within a SnackBarContextProvider');
    }
    return context;
};

// Also export SnackBarContext as default if needed for legacy reasons
export default SnackBarContext;