// QuickActionOptions.js
import React, { useState, useEffect } from "react";
import SpeedDial from "@mui/material/SpeedDial";

import SpeedDialAction from "@mui/material/SpeedDialAction";
import DrawerRight from "../../UI/DrawerRight";
import IconButton from "@mui/material/IconButton";

import P4lIcon from "../../UI/P4lIcon";

import ChatWindow from "../../FloatingChat/ChatWindow";
const QuickActionOptions = ({ actions,menuItems }) => {
  const [open, setOpen] = useState(false);
  const [chatIsOpen, setChatOpen] = useState(false);

  const [alias, setAlias] = useState('');
  const [destination, setDestination] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);






  const handleCloseDrawer = () => {
    // Checking the button type

     setDrawerOpen(false);

    // Add else if or switch case for other types if necessary
  };

  const toggleChat = () => {
    if (!chatIsOpen){
      setOpen((prevOpen) => !prevOpen);
    }

    setChatOpen(!chatIsOpen);

  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  }

  //console.log(menuItems)
  //console.log(drawerOpen)

  const handleClickOutside = (event) => {
    const button = document.getElementById("speed-dial-button");
    if (button && !button.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleActionClick = (alias,destination) => {

    setAlias(alias);
    //setFormKey((prevValue) => prevValue+1);
    setDestination(destination);
    //console.log(alias)
    setDrawerOpen(true);
    //setSelectedActionData(action.data);

  };





  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: "absolute", top: "100%", right: 1 }}
        icon={<IconButton size="small"><span className={` text-white ${open ? "p4l-cancel-circle" : "p4l-three-bars"} `} id="speed-dial-button"></span></IconButton>}
        open={open}
        onClick={handleToggle}
        direction="left"
      >

        { menuItems.map((item) => (
          <SpeedDialAction
            key={item.alias}
            icon={<P4lIcon size="small" icon={item.icon} />}
            tooltipTitle={item.name}
            onClick={() => handleActionClick(item.alias,item.parent)}
          />
        ))}
          <SpeedDialAction
            key={'chat'}
            icon={<P4lIcon size="small" icon={'p4l-chat'} />}
            tooltipTitle={'chat'}
            onClick={toggleChat}
          />
      </SpeedDial>


      {(alias || destination) && <DrawerRight

open={drawerOpen}
destination={destination}
alias={alias}
width="50%"

handleClose={handleCloseDrawer}



/>}


{chatIsOpen && <ChatWindow closeChat={toggleChat} />}
    </>
  );
};

export default  QuickActionOptions;
