import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { fetchHolidayEvents } from '../utils/cms'
import useTranslations from '../hooks/use-translations';
import React, { useState, useEffect } from 'react';
export default function Calendar() {
  const [events, setEvents] = useState([]);
  const [locale, setLocale] = useState('pt'); // Default to English
  const language = localStorage.getItem("language") || "pt";
  const translate = useTranslations(language);
  const [translations, setTranslations] = useState({
    holidays:'',
  });

  useEffect(() => {


    // Dynamically import the locale based on the stored language
    const loadLanguage = async () => {
      if (language === 'pt') {
        const ptLocale = (await import('@fullcalendar/core/locales/pt')).default;
        setLocale(ptLocale);
      } else {
        setLocale(language);
      }
    };

    loadLanguage();

    const getHolidays = async () => {
      try {
        const holidayEvents = await fetchHolidayEvents();
        setEvents(holidayEvents);
      } catch (error) {
        console.error('Error fetching holiday events:', error);
      }
    };

    getHolidays();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loadTranslations = async () => {
      const holidays = await translate('holidays'); // Use your actual translation key
      setTranslations({ holidays });
    };

    loadTranslations();
  }, [translate]);


  const headerToolbar = {
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
  };


  const mobileHeaderToolbar = {
    right: 'prev,next today',
    center: 'title',
    left: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
  };


  console.log(events)
  return (
    <div className="sm:p-4">
      <div className="flex items-center mb-4">
        <div className="w-5 h-5 bg-[#10B981] mr-2"></div>
        <span className="text-sm">{translations.holidays}</span>
      </div>
      <FullCalendar
           plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
           headerToolbar={window.innerWidth < 768 ? mobileHeaderToolbar : headerToolbar}
        initialView="dayGridMonth"
        events={events}
        eventDisplay="block" // Ensure all-day events are displayed properly
        locale={locale}
      />
    </div>
  );
}