import React, { useState,useEffect } from 'react';
import Breadcrumb from '../../ Breadcrumb/ Breadcrumb';
import Tabs from './Tabs';
import { getCSRFToken } from '../../../utils/auth';
import { useLoaderData ,useParams} from 'react-router-dom';
import {CircularProgress } from '@mui/material';

import { useNavigation,useNavigate } from 'react-router-dom';

import { json } from 'react-router-dom';
import { extractFields,fetchDataObjectDetail } from '../../../utils/cms';

import Box from '@mui/material/Box';
import Form from './Form';
import { useSnackBar } from '../../../store/context/snackbar-context';

import MetaData from '../MetaData';
const FormLayout = ({ formData,submitted,formKey, context ,submitHandler, className, colSpan,formType,actionDataAlternative,isSubmitting,action }) => {
  //console.log(context);
  const [formContent, setFormContent] = useState(undefined);
  const [tabs, setTabs] = useState(undefined);
  const [loaderData,setLoaderData]=useState(undefined);
  const { notify } = useSnackBar();
const [selectedTab,setSelectedTab]=useState(undefined);
const [error, setError] = useState(null);
  const navigation = useNavigation();
  const navigate = useNavigate();
  const rawData = useLoaderData();

    const data = formData ? formData : rawData;

  console.log(data);
    //console.log(formData)
    //console.log(rawData)
  const meta = data?.meta || "";
  const breadcrumb = data?.breadcrumb.data || "";

  const params = useParams();


  //console.log(formData)
  //console.log(selectedTab)
  useEffect(() => {
    async function fetchData() {
        try {
            if (!data) return;

            const keys = Object.keys(data.items);
            const formId = keys[0];
            const tabsData = data.items[formId]?.tabs;
            let formDataObj;

            if (tabsData) {
                if (!selectedTab) {
                    setSelectedTab(tabsData[0].value);
                    formDataObj = data.items[formId].formData[tabsData[0].value];
                } else {
                    formDataObj = data.items[formId].formData[selectedTab];
                }
            } else {
                formDataObj = data.items[formId]?.formData;
            }
            if(!formDataObj){
              notify("No form object found", 'error'); // Use the notify function from your context
              navigate("/"); // Optiona
              return;
            }
            setFormContent(formDataObj);
            console.log(formDataObj);
            const fields = extractFields(formDataObj.fields);
            const dataForm = {
                ...formDataObj.object,
                fields: fields,

                model_id: context !== 'new' ? params.id : null
            };

            const formDetailData = await fetchDataObjectDetail(dataForm, "detail");
            console.log(formDetailData);
            setLoaderData(formDetailData);
            setTabs(tabsData);
        } catch (error) {
          console.log(error);
          setError("Object not found");




        }
    }

    fetchData();
    // eslint-disable-next-line
}, [selectedTab, data]); // dependencies
  //console.log(loaderData && loaderData.data)
  //console.log(formContent)
  //console.log(tabs)

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
    // navigate(`/${newValue}`); // Uncomment if navigation is needed
  };


  useEffect(() => {

    if (error) {
      console.log(error);
      notify(error, 'error'); // Use the notify function from your context
      navigate("/"); // Optiona
      setError(undefined);
    }
    // eslint-disable-next-line
}, [error, notify, navigate]);

return(formContent &&

(<React.Fragment>
<Breadcrumb data={breadcrumb}/>
  {tabs ? (

            <Tabs className="w-full " tabs={tabs} selectedTab={selectedTab} onTabChange={handleTabChange}>
              {navigation.state === 'loading' ? (<CircularProgress/>) : (

                  <>
                  { loaderData && <Form action={action ? action : ""} formKey={formKey} context={context} formType={formType} submitHandler={submitHandler} loaderData={loaderData.data} className={className} formContent={formContent} colSpan={colSpan}/>}
                  </>

              )}
            </Tabs>


      ) : (
        loaderData && <Box  className='w-full p-2 '> <Form action={action ? action : ""} formKey={formKey} context={context} formType={formType} submitHandler={submitHandler} loaderData={loaderData.data} className={className} formContent={formContent} colSpan={colSpan} submitted={submitted}/></Box>
      )}
  <MetaData data={JSON.stringify(meta)}/>
</React.Fragment>)
)
};



export default FormLayout;

export async function action({ request, params },action='update') {

  const data = await request.formData();
  const id = params.id;

  //console.log("entra aqui")
  //console.log(data)

  const formData = { data: {} };

  const url = `webapp/updater`;



  const token = getCSRFToken();

  const model_name = data.get('model_name');
  const app_name = data.get('app_name');
  const model_key_id = data.get('model_key_id');

  data.forEach((value, key) => {

    if(key!=='context' && key!=='token' && key!=='url' && key!=='method' && key!=='model_name' && key!=='app_name' && key!=='model_key_id')
      formData.data[key] = value;
  }
  );


//console.log(formData)
const headers = token ? { 'X-CSRFToken': token, 'Content-Type': 'application/json' } : { 'Content-Type': 'application/json' };
const body = {
  "action": action,
  "app_name": app_name,     // Using variable app_name
  "model_name": model_name, // Using variable model_name
  "model_key_id": model_key_id, // Using variable model_key_id
  "model_id": id,     // Using variable model_id

  "values": formData.data ,         // Using variable fields


};

const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
  method: 'POST',
  headers: headers,
  body: JSON.stringify(body),
        credentials:"include"
});

  //console.log(response)
  if (response.status === 400) {
    const errorData = await response.json();
    //console.log(errorData)
    return { ok: false, status: response.status, data: errorData };
  }


  if (!response.ok)
  {
    if (!response.ok) {
      throw json({ message: 'Dados inválidos' }, { ok: false });
    }
  }

  return response;
}


export async function loader(loaderData) {
  const { REACT_APP_API_URL } = process.env;
  const token = getCSRFToken();
  if(!loaderData){
    return null;
  }

    const response = await fetch(REACT_APP_API_URL+loaderData.url, {
      method: loaderData.method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': token,
      },
            credentials:"include"

    });

    if (!response.ok) {
      //console.error(`Error loading data from ${loaderData.url}. Status: ${response.status}`);
      //console.log("throw json aqui")

      throw json({ message: 'Could not authenticate user.' }, { status: 401 });
    }

    const data = await response.json();
    //console.log(data);

    return data;

}
