// ** React Imports
import { useState, useEffect } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'

// ** Icon Imports
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'

// ** Third Party Components
import clsx from 'clsx'
import { useKeenSlider } from 'keen-slider/react'
import { Link } from 'react-router-dom'
// ** Keen Slider CSS Import
import 'keen-slider/keen-slider.min.css';

const Swiper = ({ direction = 'ltr', autoplay = 12000 }) => {
  // ** States
  const [loaded, setLoaded] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  // ** Hook
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    rtl: direction === 'rtl',
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    }
  })

  // Auto-switching effect
  useEffect(() => {
    if (instanceRef.current) {
      const timer = setInterval(() => {
        instanceRef.current.next()
      }, autoplay)
      return () => clearInterval(timer)
    }
  }, [instanceRef, autoplay])

  return (
    <>
      <Box className='navigation-wrapper w-4/5 sm:w-1/2 mx-auto' sx={{ position: 'relative' }}>
        <Box ref={sliderRef} className='keen-slider rounded-lg'>

            <Link to={"https://docs.p4lpro.com/cards/dap"}>
          <Box className='keen-slider__slide'>
            <img src='https://demos.themeselection.com/marketplace/materio-mui-react-nextjs-admin-template/demo-1/images/banners/banner-1.jpg' alt='swiper 1' />
          </Box>
          </Link>
          <Link to={"https://docs.p4lpro.com/cards/demo"}>
          <Box className='keen-slider__slide'>
            <img src='https://demos.themeselection.com/marketplace/materio-mui-react-nextjs-admin-template/demo-1/images/banners/banner-2.jpg' alt='swiper 2' />
          </Box>
          </Link>
          <Box className='keen-slider__slide'>
            <img src='https://demos.themeselection.com/marketplace/materio-mui-react-nextjs-admin-template/demo-1/images/banners/banner-3.jpg' alt='swiper 3' />
          </Box>
          <Box className='keen-slider__slide'>
            <img src='https://demos.themeselection.com/marketplace/materio-mui-react-nextjs-admin-template/demo-1/images/banners/banner-4.jpg' alt='swiper 4' />
          </Box>
          <Box className='keen-slider__slide'>
            <img src='https://demos.themeselection.com/marketplace/materio-mui-react-nextjs-admin-template/demo-1/images/banners/banner-5.jpg' alt='swiper 5' />
          </Box>
        </Box>
        {loaded && instanceRef.current && (
          <>
            <IconButton
              className={clsx('arrow arrow-left')}
              sx={{
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translateY(-50%)',
                zIndex: 10,
              }}
              onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
            >
          <span class="p4l p4l-circle-left"></span>
            </IconButton>

            <IconButton
              className={clsx('arrow arrow-right')}
              sx={{
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translateY(-50%)',
                zIndex: 10,
              }}
              onClick={e => e.stopPropagation() || instanceRef.current?.next()}
            >
             <span class="p4l p4l-circle-right"/>
            </IconButton>
          </>
        )}
      </Box>

    </>
  )
}

export default Swiper
