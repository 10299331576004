import React from "react";
import {  Box, Typography,Grid,Avatar } from "@mui/material";
import P4lIcon from "../UI/P4lIcon";

function StateCard(props) {
  const { colSpan } = props;

  return (
    <Grid item xs={12} sm={6} md={4} lg={colSpan}>
     <Box  sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          variant='rounded'
          color="success"
          sx={{ mr: 3, boxShadow: 3, width: 44, height: 44, '& svg': { fontSize: '1.75rem' } }}
        >
         <P4lIcon size="large" className="text-green-600" icon="p4l-person-tie" />
        </Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='caption'>Trabalhadores</Typography>
          <Typography variant='h6'>55</Typography>
        </Box>
      </Box>
    </Grid>
  );
}

export default StateCard;
