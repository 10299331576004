import { Typography } from "@mui/material"
import FormLayout from "../components/UI/DynamicForm/FormLayout";
import LinkMui from '@mui/material/Link';
import { Link } from "react-router-dom";
import AuthForm from "../components/Auth/AuthForm";

import useTranslations from "../hooks/use-translations";
import React, { useState, useEffect } from "react";
import { useActionData } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getCSRFToken } from "../utils/auth";
function ResetPassword(){
  const actionData = useActionData();
  const navigate = useNavigate();
    const language = localStorage.getItem("language") || "pt";
    const translate = useTranslations(language);
    console.log(actionData);
    const [translations, setTranslations] = useState({
      resetInstructions: '',
      backLogin: '',
      forgotPassword:''
    });

    if (actionData && actionData.ok) {
      navigate("/notification", {
          state: {
              title:"reset-password",
              message: "notification-reset-password-success",

              // Assuming you're sending back the email as part of the actionData
          }
      });
  }




    useEffect(() => {
        const loadTranslations = async () => {
          const resetInstructions = await translate('reset-password-instructions'); // Use your actual translation key
          const backLogin = await translate('reset-password-back-login'); // Use your actual translation key
          const resetPassword = await translate('reset-password'); // Use your actual translation key
          setTranslations({ resetInstructions, backLogin,resetPassword });
        };

        loadTranslations();
      }, [translate]);

    return(
<>
<AuthForm social='disable'>

<Typography className="text-white" variant='h5'> {translations.resetPassword}</Typography>
<Typography className="text-white" variant='body1'>
{translations.resetInstructions}
              </Typography>
              <FormLayout colSpan={12} formType="router" className="flex flex-col items-center gap-8" />
              <Typography className="flex gap-2 text-white" variant="body2">

        <Link to="/login"><LinkMui sx={{ color: 'primary.accent', textDecoration: 'underline' }}>{translations.backLogin}</LinkMui></Link>
      </Typography>

      </AuthForm>

</>
    );
}

export default ResetPassword;


export async function action({ request,params }) {
    //console.log(params)
    const {token} = params;
    const { REACT_APP_API_URL } = process.env;


    const data = await request.formData();


    const someData = {
      password: data.get('password'),
      "confirm_password": data.get('confirm_password'),

    };

    if(someData.password !== someData["confirm_password"]){

      return { ok:false, "confirm_password": ['Passwords dont match'] };
     }

     const authData = {
      password: data.get('password'),

      key:token
    };
await fetch(process.env.REACT_APP_API_URL +'appauth/get-csrf-token/', {
      method: 'GET',
      credentials: 'include',  // Ensure cookies are included in the request
    });

    const response = await fetch(REACT_APP_API_URL+`_allauth/browser/v1/auth/password/reset` , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCSRFToken(),

      },
      credentials: 'include',
      body: JSON.stringify(authData),
    });

    if (response.status === 400) {
      const errorData = await response.json();
      //console.log(errorData)
      return { ok: false, status: response.status, data: errorData };
    }

    if (response.status === 401 || response.status===200 ) {
      await response.json();
      return {ok:true , response:response};
    }

await response.json();
    //console.log(resData)
    //return redirect('/login');
    return {ok:true , response:response};


  }
