import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import QuickActionOptions from "./QuickActionOptions";

const FloatingMenu = React.memo(({ data, footerRef }) => {
  const [bottomPosition, setBottomPosition] = useState(44);

  const adjustMenuPosition = () => {
    if (!footerRef.current) return;
    const footerRect = footerRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    if (footerRect.top < viewportHeight) {
      const overlap = viewportHeight - footerRect.top + 70;
      setBottomPosition(overlap+"px");

    } else {
      setBottomPosition("6rem");
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', adjustMenuPosition);
    window.addEventListener('resize', adjustMenuPosition);
    adjustMenuPosition();
    return () => {
      window.removeEventListener('scroll', adjustMenuPosition);
      window.removeEventListener('resize', adjustMenuPosition);
    };
    // eslint-disable-next-line
  }, [footerRef]);

  return (
    <Box className={`hidden sm:block fixed z-10 right-3 `} style={{ bottom: `${bottomPosition}` }}>
      <QuickActionOptions menuItems={data.items} />
    </Box>
  );
});

export default FloatingMenu;
