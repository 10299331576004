import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Box, CardContent, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AuthForm from '../components/Auth/AuthForm';
import useTranslations from '../hooks/use-translations';

function NotificationPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { message, title, email, buttonText, instructionText } = location.state || {}; // Receiving dynamic text from state

  const language = localStorage.getItem("language") || "pt";
  const translate = useTranslations(language);

  const [translations, setTranslations] = useState({
    notificationMessage: '',
    notificationTitle: '',
    buttonLabel: '',
    instructions: '',
  });

  useEffect(() => {
    if (!message) {
      navigate('/', { replace: true });
    }
  }, [message, navigate]);

  useEffect(() => {
    const loadTranslations = async () => {
      const notificationMessage = await translate(message);
      const notificationTitle = await translate(title);
      const buttonLabel = await translate(buttonText || 'IR PARA O E-MAIL');
      const instructions = await translate(instructionText || 'Para concluir o registo, carregue no link que lhe enviámos agora por e-mail.');

      setTranslations({ notificationMessage, notificationTitle, buttonLabel, instructions });
    };

    loadTranslations();
  }, [translate, message, title, buttonText, instructionText]);

  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '10px 24px',
    fontWeight: 'bold',
    borderRadius: '50px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  }));

  const CenterBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    flexDirection: 'column',
  });

  return (
    <>
      {message && (
        <div className='bg-grey'>

          <CenterBox>
            <CheckCircleIcon
              sx={{
                fontSize: 100,
                color: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
              }}
            />
            <Typography variant="h4" sx={{ fontWeight: 'bold', mt: 4 }}>
              {translations.notificationTitle}
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
              {translations.notificationMessage} {email && <strong>{email}</strong>}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2, color: '#777' }}>
              {translations.instructions}
            </Typography>

            <StyledButton
              component={Link}
              to="/email" // Change this to the correct email route
              sx={{ mt: 4 }}
            >
              {translations.buttonLabel}
            </StyledButton>
          </CenterBox>

        </div>
      )}
    </>
  );
}

export default NotificationPage;
