import React, { useRef, useState, useEffect } from 'react';


const Tabs = ({ tabs, selectedTab, onTabChange, children }) => {
  const scrollRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);

  // Check for overflow and update arrow visibility
  useEffect(() => {
    const checkForOverflow = () => {
      const { current } = scrollRef;
      if (current) {
        const scrollable = current.scrollWidth > current.clientWidth;
        setIsScrollable(scrollable);
      }
    };

    checkForOverflow();
    // Add event listener for resize to re-check overflow
    window.addEventListener('resize', checkForOverflow);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkForOverflow);
    };
  }, []);




  return (
    <div>
    <div id='parent-div' className='relative'>

      <div ref={scrollRef} className="overflow-x-auto whitespace-nowrap text-center flex justify-start sm:justify-center bg-gray-100 text-2xl">
        {tabs.map(tab => (
          <button
            key={tab.value}
            onClick={() => onTabChange(tab.value)}
            className={` border inline-block min-w-64 py-9 px-2 transition-all duration-200 ease-out ${
              selectedTab === tab.value
                ? 'bg-white text-black font-bold py-7'
                : 'text-gray-400 hover:bg-white hover:text-black hover:font-bold hover:py-7 active:bg-white active:text-black active:py-7'
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

          </div>
      <div className="w-full p-6 bg-white">
        {tabs.map(tab => (
          <div key={tab.value} className={`${selectedTab !== tab.value ? 'hidden' : ''}`}>
            {selectedTab === tab.value && children}
          </div>
        ))}
      </div>
      </div>

  );
};

export default Tabs;
