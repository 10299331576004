import React, { useState ,useEffect,useContext} from "react";



import FormLayout from "./DynamicForm/FormLayout";





import SnackBarContext from "../../store/context/snackbar-context";


const DynamicLayoutRoot = ({parentItem,context }) => {



  const { handleformSubmitted  } = useContext(SnackBarContext);


  // eslint-disable-next-line
  const [selectedTab, setSelectedTab] = useState(context);




//console.log(loaderData)

  //console.log(formData)





  const handleSubmit = () => {


    handleformSubmitted(true);
  };

useEffect(() => {
  const loadFormData = async () => {
    try {


    } catch (error) {
     //console.log(error)

       // Navigate to the home page
      // Set an error state here
      // setErrorState(error.message);
    }
  };

  loadFormData();
}, [selectedTab]);



  return (
    <React.Fragment>


 <FormLayout context={'update'} submitHandler={handleSubmit} />



    </React.Fragment>
  );
};

export default DynamicLayoutRoot;