import { Outlet } from 'react-router-dom';
import Nav from '../components/Nav/Nav';
import Box from '@mui/material/Box';
import Footer from '../components/Footer/Footer';
import MainSection from '../components/UI/MainSection';
import FloatingMenu from '../components/Nav/FloatingMenu/FloatingMenu';
import React, { useState,useEffect,useMemo,useRef } from 'react';
import AuthContext from '../store/context/auth-context__';
import ProfileProgressBar from '../components/ProfileProgressBar/ProfileProgressBar';
import { useNavigate } from 'react-router-dom';
import {  useNavigation  } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import { fetchMenuItems } from '../utils/cms';

import ScrollToTop from '../components/ScrollToTop';
import { useCallback } from 'react';

import { useContext } from 'react';

function Root() {
  const drawerWidth = 240;

  const [open, setOpen] = useState(false);
  const navigation = useNavigation();
  const navigate = useNavigate();
  const footerRef = useRef(null); // Create a ref for the footer

  const [sideBarItems, setSidebarItems] = useState([]);
  const [topNavItems, setTopNavItems] = useState([]);
  const [footerItems, setFooterItems] = useState([]);
  const [floatingMenuItems, setFloatingMenuItems] = useState({ items: [] });


  const authCtx = useContext(AuthContext);
  console.log(authCtx);





  useEffect(() => {
    authCtx.refetch();
    if(!authCtx.isLoggedIn){
      navigate("/login");
    }

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if(!authCtx.isLoggedIn) return;
    async function loadData() {
      const sidebarData = await fetchMenuItems('sidebar-menu');
      const footerData = await fetchMenuItems('footer-menu');
      const topNavData = await fetchMenuItems('top-menu');
      const floatingMenuData = await fetchMenuItems('floating-menu');

      setSidebarItems(sidebarData || []);
      setFooterItems(footerData || []);
      setTopNavItems(topNavData || []);
      setFloatingMenuItems(floatingMenuData || { items: [] });
    }

    loadData();
    // eslint-disable-next-line
  }, [authCtx.isLoggedIn]);

  // Memoizing handlers to ensure their stability
  const handleDrawerOpen = useCallback(() => setOpen(true), []);
  const handleDrawerClose = useCallback(() => setOpen(false), []);

  // Memoizing props objects to ensure their stability
  const memoizedSideBarItems = useMemo(() => sideBarItems, [sideBarItems]);
  const memoizedTopNavItems = useMemo(() => topNavItems, [topNavItems]);
  const memoizedFooterItems = useMemo(() => footerItems, [footerItems]);
  const memoizedFloatingMenuItems = useMemo(() => floatingMenuItems, [floatingMenuItems]);
  console.log(memoizedTopNavItems);
  return (
authCtx.isLoggedIn &&
    <React.Fragment>

      <ScrollToTop>

        <Box className="w-full" sx={{ height: '100%', display: 'flex' }}>
          <Nav
            topNavItems={memoizedTopNavItems}
            sideBarItems={memoizedSideBarItems}
            drawerWidth={drawerWidth}
            open={open}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
          />

          <Box sx={{ flexGrow: 1,  minWidth: 0, display: 'flex', flexDirection: 'column', minHeight: '100vh' }} className="w-full bg-primary-300 ">

          <main sx={{ flexGrow: 1, width: '100%', transition: 'padding .25s ease-in-out' }}>

              <MainSection>
              <ProfileProgressBar/>
                <FloatingMenu footerRef={footerRef} data={memoizedFloatingMenuItems} />

                {navigation.state === 'loading' ? (
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 'screen' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Outlet />
                )}

              </MainSection>

            </main>

            <Footer ref={footerRef} footerItems={memoizedFooterItems} />
          </Box>

        </Box>

      </ScrollToTop>

    </React.Fragment>

  );
}

export default Root;