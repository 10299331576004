import React, { useEffect, useState } from 'react';
import { useLoaderData,json } from 'react-router-dom';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitizing HTML
import Breadcrumb from '../components/ Breadcrumb/ Breadcrumb';
import { Link } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { getCSRFToken } from '../utils/auth';

import Chartjs from '../components/Widgets/Chart';

function ContentPage() {
  const loaderData = useLoaderData();
  const htmlData = loaderData?.items[0];
  console.log(loaderData);
  const [content, setContent] = useState(null);


const breadcrumb = loaderData?.breadcrumb?.data || '';

 // Tags={}
  // Extend the allowed tags for DOMPurify
  DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (node.tagName && (node.tagName.toLowerCase() === 'chart' || node.tagName.toLowerCase() === 'bootstrap')) {
      data.allowedTags[node.tagName.toLowerCase()] = true;


    }
  });

  DOMPurify.addHook('uponSanitizeAttribute', (node, data) => {
    if (node.tagName.toLowerCase() === 'chart' || node.tagName.toLowerCase() === 'bootstrap') {
        // This line assumes you want to allow all attributes. Modify if only specific attributes are needed.
        data.allowedAttributes[data.attrName] = true;
    }
});

const sanitizeAndParseContent = () => {
  if (!htmlData || !htmlData.text) return null;
  const safeHTML = DOMPurify.sanitize(htmlData.text, { ADD_TAGS: ['chart', 'bootstrap'], ADD_ATTR: ['target','data-click'] });
  const options = {
    replace: domNode => {
      if (domNode.attribs && domNode.name === 'chart') {
        return <Chartjs type={domNode.attribs.type} data={domNode.attribs.data} options={domNode.attribs.options} />;
      } else if (domNode.attribs && domNode.name === 'a') {
        let href = domNode.attribs.href;
        let className = domNode.attribs.class;
        let target = domNode.attribs.target;
        return (
          <Link className={className} target={target} to={href}>
            {domToReact(domNode.children, options)}
          </Link>
        );
      }
    }
  };
  return parse(safeHTML, options);
};

useEffect(() => {

  window.showTabContent = function(tabId) {
    const tabContents = document.querySelectorAll('.tab-content');
    const tabButtons = document.querySelectorAll('.tab-button');

    // Hide all tab content
    tabContents.forEach(content => content.classList.add('hidden'));

    // Remove the active class from all tab buttons
    tabButtons.forEach(button => button.classList.remove('active'));

    // Show the selected tab content
    const selectedTab = document.getElementById(tabId);
    if (selectedTab) {
        selectedTab.classList.remove('hidden');
    }

    // Add the active class to the corresponding tab button
    const activeButton = document.querySelector(`[data-tab="${tabId}"]`);
    if (activeButton) {
        activeButton.classList.add('active');
    }
};

window.showFirstTab = function() {
    const firstTab = document.querySelector('.tab-content');
    const firstTabButton = document.querySelector('.tab-button');

    if (firstTab) {
        firstTab.classList.remove('hidden');
    }

    if (firstTabButton) {
        firstTabButton.classList.add('active');
    }
};

  const sanitizedContent = sanitizeAndParseContent();
  setContent(sanitizedContent);

  const loadScript = (src, callback) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = callback;
    document.head.appendChild(script);
  };



  const genericFunctionsScriptSrc = `${process.env.REACT_APP_API_URL}static/cms/js/lib.js`;
  loadScript(genericFunctionsScriptSrc, () => {
    console.log('Additional script loaded successfully');
    if (typeof window.doStuff === 'function') {
      window.doStuff();
      const buttons = document.querySelectorAll('[data-click]');
      buttons.forEach(button => {
        const clickHandler = new Function(button.getAttribute('data-click'));
        button.addEventListener('click', clickHandler);
      });
      // Ensure the first tab is shown after everything is set up
      window.showFirstTab();
      console.log("generic script initialized!")
    } else {
      console.log('doStuff function is not defined');
    }
  });




  return () => {
    const script = document.querySelector('script[src="https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.4.1/flowbite.js"]');
    if (script) {
      document.head.removeChild(script);
    }
    const genericFunctionsScript = document.querySelector(`script[src="${genericFunctionsScriptSrc}"]`);
    if (genericFunctionsScript) {
      document.head.removeChild(genericFunctionsScript);
    }
  };
  // eslint-disable-next-line
}, []);

useEffect(() => {
  if (content) {




    const flowbiteScript = document.createElement('script');
    flowbiteScript.type = 'application/javascript';
    flowbiteScript.text = `
      if (typeof initFlowbite !== 'undefined') {
        initFlowbite();
      } else {
        console.warn('initFlowbite function is not defined');
      }
    `;
    document.body.appendChild(flowbiteScript);

    // Run the doStuff function again if the content changes
    if (typeof window.doStuff === 'function') {
      window.doStuff();
      const buttons = document.querySelectorAll('[data-click]');
      buttons.forEach(button => {
        const clickHandler = new Function(button.getAttribute('data-click'));
        button.addEventListener('click', clickHandler);
      });
      // Ensure the first tab is shown after everything is set up
      window.showFirstTab();
      console.log("doStuff function called after content change!");
    } else {
      console.log('doStuff function is not defined after content change');
    }



  }
}, [content]); // Dependency array includes 'content'

return (
  <React.Fragment>
    <Breadcrumb data={breadcrumb} />
    <div className="w-full">
      {content || <p>Loading...</p>}
    </div>
  </React.Fragment>
);
}
export default ContentPage;




export async function loader({ params, request }) {
  const { REACT_APP_API_URL } = process.env;


  const token = getCSRFToken();
  console.log(token);

  const id = params.id || 'home'


  try {
    const response = await fetch(REACT_APP_API_URL+"webapp/item/html/"+id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
       'X-CSRFToken': token,
      },
  credentials:"include"
    });

    if (!response.ok) {

      throw json({ message: response.statusText }, { status: response.status });
    }

    const data = await response.json();
    console.log(data);

    return data;
  } catch (error) {
    throw json({ message: error.text }, { status: error.status });


  }
}
