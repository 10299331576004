import Cookies from "js-cookie";
import React, { useState, useEffect, useMemo } from "react";
import { fetchCompanyLogo } from "../../utils/cms";
import { Helmet } from 'react-helmet';

function PlatFormLogo(props) {
    const [logoUrl, setLogoUrl] = useState("");
    const [brandName, setBrandName] = useState("");
    const [logoDataRaw, setLogoDataRaw] = useState("");
    const { REACT_APP_API_URL } = process.env;
    // useMemo to memoize companyName based on the cookie value
    const companyName = useMemo(() => Cookies.get('brand') || 'planning4life', []);

    useEffect(() => {
        const fetchLogo = async () => {
            const logoData = await fetchCompanyLogo(companyName);
            setLogoDataRaw(logoData);

            const url = props.type === 'entrance' ?
                        REACT_APP_API_URL.slice(0, -1) + logoData.entrance_logo_url :
                        REACT_APP_API_URL.slice(0, -1) + logoData.small_logo_url;
            if (logoData && (logoData.entrance_logo_url || logoData.small_logo_url)) {
                setLogoUrl(url);
            }
            setBrandName(logoData.name || "Plataforma");
        };

        fetchLogo();
        // eslint-disable-next-line
    }, [companyName,props.type]); // companyName is a dependency here



    return (
        <React.Fragment>
            <Helmet>
                <title>{brandName}</title>
                <meta property="og:image" content={REACT_APP_API_URL.slice(0, -1) + logoDataRaw.entrance_logo_url} />
            </Helmet>
            {logoUrl && <img src={logoUrl} className={props.className} alt="Logo" />}
        </React.Fragment>
    );
}

export default React.memo(PlatFormLogo);
