import React from 'react';
import { Grid,Card ,Typography} from '@mui/material';
const GroupCard = ({ columns, children ,backgroundColor,title}) => {
  return (
    <Card style={{backgroundColor: `${backgroundColor}`}} className='p-4 flex flex-col gap-8' elevation={0}>
         <Typography variant="h6">{title}</Typography>
    <Grid container spacing={2}>
      {children}
    </Grid>
    </Card>
  );
};

export default GroupCard;