import React, { useState, useEffect,useContext } from "react";
import { Link,redirect,useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import LinkMui from "@mui/material/Link";
import FormLayout from "../components/UI/DynamicForm/FormLayout";
import AuthForm from "../components/Auth/AuthForm";
import AuthContext from "../store/context/auth-context__";
import { json,useActionData } from 'react-router-dom';
import { useSnackBar } from "../store/context/snackbar-context";

import useTranslations from "../hooks/use-translations";


function Login() {


  const data = useActionData();
  const authCtx = useContext(AuthContext);

  const language = localStorage.getItem("language") || "pt";
  const navigate = useNavigate();
  const { notify } = useSnackBar();
  //console.log(loaderData);
  const translate = useTranslations(language);


  const [translations, setTranslations] = useState({
    noAccount: '',
    registerHere: ''
  });

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      navigate('/');
    }
  }, [authCtx.isLoggedIn, navigate]);





      useEffect(() => {
        const fetchCsrfToken = async () => {
          const response = await fetch(process.env.REACT_APP_API_URL +'appauth/get-csrf-token/', {
            method: 'GET',
            credentials: 'include',  // Ensure cookies are included in the request
          });
          const result = await response.json();
          console.log(result);
         localStorage.setItem('csrfToken',result.csrf_token);
        };

        fetchCsrfToken();
      }, []);

  useEffect(() => {
    const loadTranslations = async () => {
      const noAccount = await translate('login-no-account'); // Use your actual translation key
      const registerHere = await translate('login-register-here'); // Use your actual translation key
      setTranslations({ noAccount, registerHere });
    };

    loadTranslations();
  }, [translate]);
  useEffect(() => {
    //console.log(data)


    if(data && data.detail ){
notify(data.detail,'error');

    }
/* eslint-disable */
  }, [data]);
  // ** State



  const submitHandler = async () => {

  };


  return ( !authCtx.isLoggedIn &&
    <React.Fragment>

    <AuthForm>

<FormLayout colSpan={12} submitHandler={submitHandler} formType="router" className="flex flex-col items-center gap-8" />

<Typography className="flex gap-2 text-white " variant="body2">
{translations.noAccount}
  <Link to="/register"><LinkMui  sx={{ color: 'primary.accent', textDecoration: 'underline' }} >{translations.registerHere}</LinkMui></Link>
</Typography>

<Divider className='w-full text-white'>ou</Divider>

</AuthForm>

    </React.Fragment>
  );
}

export default Login;

export async function action({ request }) {
  const { REACT_APP_API_URL } = process.env;
  //console.log("entrou")
  //console.log(REACT_APP_API_URL)
  const searchParams = new URL(request.url).searchParams;
  const mode = searchParams.get('mode') || 'login';

  if (mode !== 'login' && mode !== 'signup') {
    throw json({ message: 'Unsupported mode.' }, { status: 422 });
  }

  const data = await request.formData();
  //console.log(data)

  const authData = {
    password: data.get('password'),
    username: data.get('username'),
  };




  const response = await fetch(REACT_APP_API_URL + '_allauth/browser/v1/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': localStorage.getItem("csrfToken"),


    },
    credentials: 'include',
    body: JSON.stringify(authData),
  });

  if (response.status === 422 || response.status === 401 || response.status===400) {
    const errorData = await response.json();
    return { ok: false, status: response.status, data: errorData };

  }

  if (!response.ok) {
    throw json({ message: 'Could not authenticate user.' }, { status: 401 });
  }

 await response.json();


  return redirect("/");

}



