import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'

import Typography from '@mui/material/Typography'

import P4lIcon from './P4lIcon'
import {useNavigate } from 'react-router-dom'
import React,{useState,useEffect} from 'react'
import {  useTheme } from '@mui/material/styles';
import FormLayout from './DynamicForm/FormLayout'
import { fetchForm } from '../../utils/cms'


import SelectDatatable from './SelectDatatable'
import { useActionDataContext } from '../../store/context/action-context'
import { useLocation } from 'react-router-dom'

function DrawerRight(props){
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const width = "50%"


    const [formKey , setFormKey] = useState(0);
    const [loaderData, setLoaderData] = useState();


    const { actionData  } = useActionDataContext();

    const [drawerOpen, setDrawerOpen] = useState(false);



//const loaderData= useLoaderData();
//console.log(loaderData);
//console.log(props.alias);
//console.log(props.open)
useEffect(() => {

  const fetchFormData = async () => {
    setLoaderData(undefined);
    try {
      // Use the fetchForm function with the appropriate form alias
      // Replace 'your_form_alias' with the actual alias for the form you need to fetch
      const data = await fetchForm(props.alias, 'form-panel');
      //console.log(data);
     setLoaderData(data);
    } catch (err) {
     //console.log(err);

    }
  };

  fetchFormData();
  setFormKey((prevValue) => prevValue+1);


}, [props.alias,props.open]); // Empty dependency array ensures this effect runs once on mount


useEffect(() => {

  if( props.open  ){

    setDrawerOpen(true);
  }
  else{
    console.log("there is no form associated")
    //notify("There is no Form associated to this item","error");
  }
  /* eslint-disable */
}, [props.open]);

useEffect(() => {
  if(!actionData || !drawerOpen) return;
  if (actionData ) {
    if(actionData.data.id){
    const pathname = location.pathname;

    // Split the pathname into segments
    const segments = pathname.split('/');

    // Remove the last segment ('new' in your case)
    segments.pop();

    // Append the id to the segments
    const newPath = props.destination ? `${props.destination}/${actionData.data.id}` : `${actionData.data.id}`;

    // Log the newPath for debugging
    //console.log(newPath);

    // Navigate to the new path, replacing the current entry in the history stack
    navigate(newPath);
}
if(actionData.ok){
  setDrawerOpen(false);
}

    //navigate(-1);

  }


  props.handleClose();
}, [actionData]); // Depend on actionData and navigate

// The rest of your component...


function  handleClose(){

  setDrawerOpen(false);
  //navigate(-1);
  props.handleClose();

}







//console.log(props.selectBoxData)
 return( <> <Drawer

    anchor='right'

    ModalProps={{ keepMounted: true }}

    onClose={props.selectBoxData ? props.onClose : handleClose}

    open={props.selectBoxData ? props.open : drawerOpen}

    sx={{ '& .MuiDrawer-paper': { width: ['100%', width] , backgroundColor: theme.palette.primary["300"], },
    zIndex: theme.zIndex.modal ,  }}
  >

    <Box
      className='sidebar-header p-6'
      sx={{

      }}
    >
      <Box sx={{  display: 'flex',
        justifyContent: 'space-between',

        p: theme => theme.spacing(3, 3.255, 3, 5.255) }} className=' p-6 '>
      <Typography variant='h6'>
      {props.title}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>

       <P4lIcon icon="p4l-cross" size="small" onClick={props.selectBoxData ? props.onClose : handleClose}/>
      </Box>
      </Box>
    </Box>

    <Box className='sidebar-body bg-primary-300' sx={{ p: theme => theme.spacing(5, 6) }}>
    {props.selectBoxData  &&  <SelectDatatable   onClose={props.onClose} onConfirm={props.onConfirm} data={props.selectBoxData} onItemSelect={props.handleItemSelect} />}
     {!props.selectBoxData && loaderData &&  <FormLayout formKey={formKey} context='new' formData={loaderData}   /> }

    </Box>

  </Drawer>


  </>
  )
}

export default DrawerRight;